<script>
import { computed } from 'vue'
import cdn from '/~/utils/cdn'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'

export default {
  name: 'community-hub-catalog-tile',
  components: {
    BaseLink,
    BaseImage,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const retailer = computed(() => props.tile.payload ?? {})
    const category = computed(() => retailer.value.categories[0])

    const defaultImage = computed(() =>
      cdn('uploadcare://45d490c5-96ca-43bc-99c6-621a63646755').url()
    )

    const imageUrl = computed(() =>
      retailer.value.logo ? cdn(retailer.value.logo).url() : null
    )

    return {
      retailer,
      category,
      imageUrl,
      defaultImage,
    }
  },
}
</script>

<template>
  <base-link
    class="relative flex cursor-pointer overflow-hidden rounded-lg"
    :to="{
      name: `${$ext.name}-retailer`,
      params: { id: tile.retailer_id },
    }"
  >
    <div class="w-[174px] flex-none">
      <base-image
        :src="imageUrl"
        :default-src="defaultImage"
        :alt="retailer.name"
        class="h-full"
        :rounded="false"
        :ratio="1"
        aria-hidden="true"
      />
    </div>
    <div
      class="relative flex w-full flex-col overflow-hidden p-[15px] text-default"
    >
      <h3 class="truncate text-base font-bold">
        {{ retailer.name }}
      </h3>
      <div
        v-if="category"
        class="mt-[5px] shrink-0 truncate whitespace-nowrap border-b pb-[15px] text-sm font-bold text-eonx-neutral-600"
      >
        {{ category }}
      </div>
      <div
        class="mt-2.5 line-clamp-3 text-sm leading-5 text-eonx-neutral-800 sm:mt-[15px]"
      >
        {{ retailer.description }}
      </div>
    </div>
  </base-link>
</template>
