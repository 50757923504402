<script>
import { watch, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import CommunityHubFilter from './components/community-hub-filter.vue'
import CommunityHubItems from './components/community-hub-items.vue'

export default {
  name: 'community-hub-catalog',
  components: {
    CommunityHubItems,
    CommunityHubFilter,
  },
  props: {
    catalogue: {
      type: String,
      default: null,
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { routeState, getContent, syncState } = useCommunityHub()
    const { page } = useEditablePage()

    watch(routeState, (value) => {
      router.replace(value)
    })

    watch(
      () => ({ ...route }),
      (to, from) => {
        if (
          to.fullPath.replace(to.hash, '') !==
          from.fullPath.replace(from.hash, '')
        ) {
          syncState({ to, from })
          getContent()
        }
      }
    )

    onBeforeMount(getContent)

    return {
      page,
      ui,
    }
  },
}
</script>

<template>
  <div class="mx-auto w-full max-w-7xl">
    <community-hub-filter v-if="ui.mobile" />
    <div v-else class="px-5 pt-2">
      <community-hub-filter />
    </div>
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
    <div class="px-2.5 sm:px-5">
      <community-hub-items />
    </div>
  </div>
</template>
